{
  "Base": "基準数",
  "Out of Order": "使用不可",
  "Lost & Found": "忘れ物",
  "Room Assignment": "新規清掃の登録",
  "Reservations": "予約",
  "Incident": "重大な設備不備",
  "Today’s checkin": "今日のチェックイン",
  "Change Dashboard Layout": "変更ダッシュボードレイアウト",
  "Select widget you want to see": "選択したいウィジェットを選択します",
  "Drag to rearrange": "ドラッグする",
  "Cancel": "取り消し",
  "Save": "保存",
  "Back": "戻る",
  "Edit": "編集する",
  "Delete": "削除",
  "Payments": "支払い",
  "New Reservations": "新しい予約",
  "Add New Reservation": "新規予約を追加",
  "summary-desc": "{from}からの予約の累積数",
  "Reservation summary": "予約概要",
  "Monthly Reservation Number": "月の予約数",
  "Alert": "通知",
  "Reservation Number by OTA": "OTA予約数",
  "Out of Order Name": "使用不可 アイテム名",
  "Property": "物件",
  "Reported time": "報告時間",
  "Status": "予約区分",
  "Checkout Date": "チェックアウト日",
  "Checkin Date": "チェックイン日",
  "Room Name": "部屋番号",
  "Cleaning time": "開始",
  "Finished time": "完了",
  "Incident Name": "重大な設備不備名",
  "Reservation ID": "予約ID",
  "Check In": "チェックイン",
  "Check Out": "チェックアウト",
  "Total Accomodation Charge": "宿泊料金",
  "Guest Name": "ゲスト名",
  "Check in status": "チェックインステータス",
  "From Partner": "取引先",
  "Request date": "リクエスト日",
  "Fee Type": "費用種類",
  "Timestamp": "タイムスタンプ",
  "past 30 days": "過去30日",
  "past 1 year": "過去1年",
  "all time": "オールタイム",
  "Lost & Found Name": "忘れ物名",
  "Detail": "詳細",
  "Details": "詳細",
  "Date range": "日付範囲",
  "Assignment Request": "割り振りをリクエスト",
  "Housekeeper Task List": "清掃担当者タスクリスト",
  "Checkout Time": "チェックアウト時間",
  "Housekeeper Company": "清掃会社",
  "Housekeeper": "清掃業者",
  "Assigned Status": "割り当て",
  "Checkout Status": "チェックアウト",
  "Housekeeping Status": "清掃ステータス",
  "CHECKED_IN": "チェックイン",
  "CONFIRMED": "確認済み",
  "COMPLETED": "完了",
  "CANCELLED": "キャンセル",
  "collected": "送付済",
  "not collected": "保管",
  "not found": "無し",
  "passed to police": "警察届済",
  "trashed": "破棄",
  "Please select": "選択してください",
  "fixed": "解決済",
  "not fixed": "未解決",
  "Export to Excel": "エクセルにエクスポート",
  "Export to CSV": "CSVにエクスポート",
  "day": "日",
  "requested": "割当前",
  "rejected": "却下済",
  "assigned": "割当済",
  "in-progress": "対応中",
  "no-show": "清掃不要",
  "finished": "清掃済",
  "WAITING_APPROVAL": "承認中",
  "REJECTED": "却下済",
  "APPROVED": "承認済み",
  "ADMIN": "管理者",
  "SALES": "販売推進",
  "DI": "データインサイト",
  "OPS": "運用",
  "CS": "CS",
  "PO": "物件オーナー",
  "HC": "清掃会社",
  "HKP": "清掃担当者",
  "fixed cost": "固定費",
  "variable cost": "変動費",
  "in operation": "稼働中",
  "non-operation": "非稼働",
  "unused": "未使用",
  "closed": "休業中",
  "contract planned": "契約予定",
  "contracted": "契約済",
  "cancelled": "解約済",
  "MC+FC": "MC+FC",
  "MC NonBrand": "MC NonBrand",
  "owned by the company": "自社保有",
  "partial operation agency": "一部運用代行",
  "local government project": "自治体案件",
  "renovation": "リノベーション”、",
  "new construction": "新築",
  "existing construction": "既築",
  "error": {
    "override_reservation": "既に予約があります。他の日付をお選びください。"
  },
  "Currency": "通貨",
  "Permission": "状態",
  "Room Type": "部屋タイプ",
  "An existing \"above\" condition already exists.": "「以下」の条件は既に登録されています",
  "An existing \"below\" condition already exists.": "「以上」の条件は既に登録されています",
  "A setting for this property already exists": "この物件のRMSは既に登録されています",
  "Year already exist in the record": "該当年は既に登録されています",
  "Property Name": "物件名",
  "Property Code": "物件コード",
  "Room Number": "部屋番号",
  "Reservation": "予約",
  "Reservation Number": "予約番号",
  "Reserved": "予約",
  "Cancelled": "キャンセル",
  "Clear": "クリア",
  "Property name does not match": "物件が一致しません",
  "Check in date does not match": "チェックイン日が一致しません",
  "Check out date does not match": "チェックアウト日が一致しません",
  "Reservation price (sales) does not match": "売上が一致しません",
  "Reservation price (Sales wthout OTA Fees) does not match": "OTA手数料抜き売上が一致しません",
  "Back to top": "Back to top"
}
