{
  "Base": "Base",
  "Out of Order": "Out of Order",
  "Lost & Found": "Lost & Found",
  "Room Assignment": "Room Assignment",
  "Reservations": "Reservations",
  "Incident": "Incident",
  "Today’s checkin": "Today’s checkin",
  "Change Dashboard Layout": "Change Dashboard Layout",
  "Select widget you want to see": "Select widget you want to see",
  "Drag to rearrange": "Drag to rearrange",
  "Cancel": "Cancel",
  "Save": "Save",
  "Back": "Back",
  "Edit": "Edit",
  "Delete": "Delete",
  "Payments": "Payments",
  "New Reservations": "New Reservations",
  "Add New Reservation": "Add New Reservation",
  "summary-desc": "Cumulative number of reservations from {from}",
  "Reservation summary": "Reservation summary",
  "Monthly Reservation Number": "Monthly Reservation Number",
  "Alert": "Alert",
  "Reservation Number by OTA": "Reservation Number by OTA",
  "Out of Order Name": "Out of Order Name",
  "Property": "Property",
  "Reported time": "Reported time",
  "Status": "Status",
  "Checkout Date": "Checkout Date",
  "Room Name": "Room Name",
  "Cleaning time": "Cleaning time",
  "Finished time": "Finished time",
  "Incident Name": "Incident Name",
  "Reservation ID": "Reservation ID",
  "Check In": "Check In",
  "Check Out": "Check Out",
  "Total Accomodation Charge": "Total Accommodation Charge",
  "Guest Name": "Guest Name",
  "Check in status": "Check in status",
  "From Partner": "From Partner",
  "Request date": "Request date",
  "Fee Type": "Fee Type",
  "Timestamp": "Timestamp",
  "past 30 days": "past 30 days",
  "past 1 year": "past 1 year",
  "all time": "all time",
  "Lost & Found Name": "Lost & Found Name",
  "Detail": "Detail",
  "Details": "Details",
  "Date range": "Date range",
  "Assignment Request": "Assignment Request",
  "Housekeeper Task List": "Housekeeper Task List",
  "Checkout Time": "Checkout Time",
  "Housekeeper Company": "Housekeeper Company",
  "Housekeeper": "Housekeeper",
  "Assigned Status": "Assigned Status",
  "Checkout Status": "Checkout Status",
  "Housekeeping Status": "Housekeeping Status",
  "CHECKED_IN": "Check In",
  "CONFIRMED": "Confirmed",
  "COMPLETED": "Completed",
  "CANCELLED": "Cancelled",
  "collected": "Collected",
  "not collected": "Not collected",
  "not found": "Not Found",
  "passed to police": "Passed to Police",
  "trashed": "Trashed",
  "Please select": "Please select",
  "fixed": "Fixed",
  "not fixed": "Not fixed",
  "Export to Excel": "Export to Excel",
  "Export to CSV": "Export to CSV",
  "day": "Days",
  "requested": "Requested",
  "rejected": "Rejected",
  "assigned": "Assigned",
  "in-progress": "In progress",
  "no-show": "No Show",
  "finished": "Finished",
  "WAITING_APPROVAL": "Waiting approval",
  "REJECTED": "Rejected",
  "APPROVED": "Approved",
  "ADMIN": "Admin",
  "SALES": "Sales",
  "OPS": "Operator",
  "CS": "Customer Support",
  "PO": "Property Owner",
  "HC": "Housekeeping Company",
  "HKP": "Housekeeper",
  "DI": "Data Insight",
  "fixed cost": "Fixed cost",
  "variable cost": "Variable cost",
  "in operation": "In operation",
  "non-operation": "Non-operation",
  "unused": "Unused",
  "closed": "Closed",
  "contract planned": "Contract planned",
  "contracted": "Contracted",
  "cancelled": "Cancelled",
  "MC+FC": "MC+FC",
  "MC NonBrand": "MC NonBrand",
  "owned by the company": "Owned by the company",
  "partial operation agency": "Partial operation agency",
  "local government project": "Local government project",
  "renovation": "Renovation",
  "new construction": "New construction",
  "existing construction": "Existing construction",
  "error": {
    "override_reservation": "There's an existing reservation on selected dates."
  },
  "Currency": "Currency",
  "Permission": "Permission",
  "An existing \"above\" condition already exists.": "An existing \"above\" condition already exists.",
  "An existing \"below\" condition already exists.": "An existing \"below\" condition already exists.",
  "A setting for this property already exists": "A setting for this property already exists",
  "Year already exist in the record": "Year already exist in the record.",
  "Property Name": "Property Name",
  "Property Code": "Property Code",
  "Room Number": "Room Number",
  "Reservation": "Reservation",
  "Reservation Number": "Reservation Number",
  "Reserved": "Reserved",
  "Cancelled": "Cancelled",
  "Clear": "Clear",
  "Property name does not match": "Property name does not match",
  "Check in date does not match": "Check in date does not match",
  "Check out date does not match": "Check out date does not match",
  "Reservation price (sales) does not match": "Reservation price (sales) does not match",
  "Reservation price (Sales wthout OTA Fees) does not match": "Reservation price (Sales wthout OTA Fees) does not match",
  "Back to top": "Back to top"
}
