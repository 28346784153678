import { createI18n } from 'vue-i18n';
import enLocaleEl from 'element-plus/lib/locale/lang/en';
import jaLocaleEl from 'element-plus/lib/locale/lang/ja';
import { getLocale } from '@/utils/locale';
import { getDefaultLang } from '@/utils/language';

import en from '@/locales/en';
import ja from '@/locales/ja';

const messages = {
  en: {
    ...en,
    el: {
      datepicker: enLocaleEl.el.datepicker,
    },
  },
  ja: {
    ...ja,
    el: {
      datepicker: jaLocaleEl.el.datepicker,
    },
  },
};

export default createI18n({
  legacy: true,
  locale: getLocale() || getDefaultLang(),
  fallbackLocale: 'en',
  availableLocales: ['en', 'ja'],
  messages,
});
